export default class Config {
  static get nodeEnv(): string {
    return process.env.NODE_ENV;
  }

  static get reactEnv(): string {
    return process.env.REACT_APP_ENVIRONMENT || '';
  }

  static get tabAppEndpoint(): string {
    return process.env.TAB_APP_ENDPOINT || '';
  }

  static get startLoginPageUrl(): string {
    return process.env.REACT_APP_START_LOGIN_PAGE_URL || '';
  }

  static get functionEndpoint(): string {
    return process.env.REACT_APP_FUNC_ENDPOINT || '';
  }

  static get clientId(): string {
    return process.env.REACT_APP_CLIENT_ID || '';
  }

  static get limeadeApiUrl(): string {
    return process.env.REACT_APP_LIMEADE_API_URL || '';
  }

  static get oneApiUrl(): string {
    return process.env.REACT_APP_ONE_API_URL || '';
  }

  static get richMediaApiUrl(): string {
    return process.env.REACT_APP_RICH_MEDIA_API_URL || '';
  }

  static get authority(): string {
    return process.env.REACT_APP_AUTHORITY || '';
  }

  static get teamsAppId(): string {
    return process.env.REACT_APP_TEAMS_APP_ID || '';
  }

  static get authClientId(): string {
    return process.env.REACT_APP_AUTH_CLIENT_ID || '';
  }

  static get authClientSecret(): string {
    return process.env.REACT_APP_AUTH_CLIENT_SECRET || '';
  }

  static get appInsightsConnectionString(): string {
    return process.env.REACT_APP_APP_INSIGHTS_CONNECTION_STRING || '';
  }

  static get storeMappingCycleMinutes(): string {
    return process.env.REACT_APP_STORE_MAPPING_CYCLE_MINUTES || '0';
  }
  static get authConfigCycleMinutes(): string {
    return process.env.REACT_APP_AUTH_CONFIG_CYCLE_MINUTES || '0';
  }
  static get expiryDaysBeforeEndDate(): string {
    return process.env.REACT_APP_EXPIRY_DAYS_BEFORE_END_DATE || '5';
  }

  static get zendeskKey(): string {
    return process.env.ZENDESK_KEY || 'e42d361c-5a3b-4191-92b4-9ecddad53d83';
  }
}
