import { app } from 'src/auth/TeamsJsWrapper';
import Config from '../Config';
import { TEAMS_URL, Tabs } from './constants';

export const navigateToTeamsTab = (tab: Tabs, context?: any) => {
  let contextStr = '';
  if (context) {
    const subEntity = { subEntityId: JSON.stringify(context) };
    const encodedContext = encodeURIComponent(JSON.stringify(subEntity));
    contextStr = `?context=${encodedContext}`;
  }

  const deeplink = `${TEAMS_URL}/l/entity/${Config.teamsAppId}/${tab}${contextStr}`;
  app.openLink(deeplink);
};
