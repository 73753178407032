import React from 'react';
import { AnimationStyles } from '@fluentui/react';
import { Flex, FlexProps, Text } from '@fluentui/react-northstar';
import { VisuallyHidden } from '@reach/visually-hidden';

import styles from './CharBarItem.styles';
import { ChartBarItemProps as IChartBarItem } from '../utils';
import { useTeamsFx } from 'src/lib/useTeamsFx';

interface ChartBarItemProps extends FlexProps {
  item: IChartBarItem;
}

const MAX_VALUE = 5;
const getPercentage = (value: number): number => +(value / (MAX_VALUE / 100)).toFixed(0);
const getBackground = (value: number, backgroundColor: string) => {
  const percentage = getPercentage(value);

  return `linear-gradient(to right, ${backgroundColor} ${percentage}%, #dfdede ${percentage}%)`;
};

const ChartBarItem: React.FC<ChartBarItemProps> = ({ item: { title, value, announcement }, ...props }) => {
  const { theme } = useTeamsFx();
  const backgroundColor = theme.siteVariables.colorScheme.brand.background;
  const background = getBackground(value, backgroundColor);
  const animationStyle = AnimationStyles.fadeIn500;
  const isAnnouncement = !!announcement;
  const charItemAnnouncement = isAnnouncement && (
    <VisuallyHidden as="span" style={{ position: 'fixed' }}>
      {announcement}
    </VisuallyHidden>
  );

  return (
    <Flex column styles={{ ...props.styles }}>
      <Text styles={styles.label} aria-hidden={isAnnouncement}>
        {title}
      </Text>

      {charItemAnnouncement}

      <Flex aria-hidden={isAnnouncement}>
        <Flex styles={{ ...styles.barLine, ...animationStyle, background }} />

        <Text styles={styles.barValue}>{value.toFixed(1)}</Text>
      </Flex>
    </Flex>
  );
};

export default ChartBarItem;
