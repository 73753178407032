import { observable, action, makeObservable } from 'mobx';
import { WebStorageStateStore } from 'oidc-client-ts';

import { RootStore } from './Root.store';
import ApiWrapper from '../utilities/apiWrapper';
import { Startup } from '../models/Startup';
import { TeamsUserLocale } from '../models/TeamsUserLocale';
import { STORED_USER_LOCALE } from '../utilities/constants';
import { InformedConsent } from 'src/models/InformedConsent';
import ExceptionTypes from 'src/utilities/exceptionTypes';
import { AadUser } from 'src/models/AadUser';
import GraphApiWrapper from 'src/utilities/graphApiWrapper';
import { GraphClient } from 'src/auth/TeamsGraphAuth';

type StartupData = Startup | undefined;

export default class UserProfileStore {
  rootStore: RootStore;
  webStorageStateStore = new WebStorageStateStore({ store: window.localStorage });

  constructor(rootStore: RootStore) {
    makeObservable(this);
    this.rootStore = rootStore;
  }

  @observable profile: any = null;
  @observable isDeviceConnected: boolean = false;
  @observable startupData: StartupData = undefined;

  @action
  setProfile = (value: any) => {
    this.profile = value;
  };

  @action
  setIsDeviceConnected = (value: boolean) => {
    this.isDeviceConnected = value;
  };

  @action
  updateProfile = async (): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const profile = await apiWrapper.getProfile();

    if (profile.Data) {
      this.setProfile(profile.Data.me);
    }
  };

  getStartupData = async (): Promise<StartupData> => {
    const shouldLoadStartupData = this.startupData === undefined || this.startupData === null;

    if (shouldLoadStartupData) {
      await this.loadStartupData();
    }

    return this.startupData;
  };

  @action
  loadStartupData = async (): Promise<void> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const startup = await apiWrapper.startup();

    if (startup) {
      this.startupData = startup;
    }
  };

  checkInformedConsent = async (): Promise<InformedConsent> => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    return await apiWrapper.checkConsent();
  };

  storeUserLocale = async (teamsUserLocale: TeamsUserLocale): Promise<void> => {
    const key = `${STORED_USER_LOCALE}_${teamsUserLocale.oid}`;
    const { oid, locale } = teamsUserLocale;

    if (!oid || !locale) {
      throw new Error(ExceptionTypes.USER_LOCALE_DATA, {
        cause: new Error(`invalid data: ${JSON.stringify({ oid: String(oid), locale: String(locale) })}`),
      });
    }
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const localStorageData: TeamsUserLocale = JSON.parse((await this.webStorageStateStore.get(key)) || '{}');
    if (localStorageData.locale !== teamsUserLocale.locale) {
      await apiWrapper.storeUserLocale(teamsUserLocale);
      this.webStorageStateStore.set(key, JSON.stringify(teamsUserLocale));
    }
  };

  getAadUsers = async (graphClient: GraphClient, aadUserIds: string[]): Promise<AadUser[]> => {
    const graphApiWrapper = new GraphApiWrapper(graphClient);
    return graphApiWrapper.getAadUsers(aadUserIds);
  };

  loadAllDeviceSyncInfo = async () => {
    const apiWrapper = new ApiWrapper(this.rootStore.AppAuthStore.getAccessToken.bind(this.rootStore.AppAuthStore));
    const result = await apiWrapper.getAllDeviceSyncInfo();
    const connectedDevices = result.filter((device) => device.connected);
    this.setIsDeviceConnected(!!connectedDevices.length);
  };

  getUserDisplayName = () => {
    return (
      this.rootStore.AppAuthStore.teamsUserState?.teamsUser?.displayName ||
      this.profile?.full_name ||
      this.profile?.user_name ||
      ''
    );
  };
}
