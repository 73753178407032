import { useEffect } from 'react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Auth0Provider } from '@auth0/auth0-react';
import { TeamsUserCredential, TeamsUserCredentialAuthConfig } from '@microsoft/teamsfx';
import { app as teamsjsApp } from '@microsoft/teams-js';

import Auth0ProviderWrapper from './Auth0ProviderWrapper';
import { LimeadeAuthProviders } from '../utilities/constants';
import { useTeamsFx } from '../lib/useTeamsFx';
import { useStore } from '../stores/Root.store';
import { useData } from '../lib/useData';
import ExceptionTypes from 'src/utilities/exceptionTypes';
import { UserInfo } from './TeamsUserState';
import { app } from './TeamsJsWrapper';
import Logger from 'src/logger/Logger';
import Config from 'src/Config';

export interface AuthProviderOptions {
  children?: React.ReactNode;
}

const AuthProvider = observer((opts: AuthProviderOptions) => {
  const { children } = opts;

  const { AppAuthStore } = useStore();

  const teamsFxData = useTeamsFx();
  const { inTeams, error, loading } = teamsFxData;
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  const setErrorState = (error: Error) => {
    AppAuthStore.setTeamsUserState({ inTeams: !!inTeams, isLoading: false, error: error });
  };

  const getAuthProvider = async () => {
    try {
      const aadTenantId = AppAuthStore.aadTenantId;
      if (!aadTenantId) {
        throw new Error(ExceptionTypes.TENANT_ID_NOT_LOADED);
      }
      const authConfig = await AppAuthStore.fetchAuthConfig(aadTenantId);

      if (authConfig?.authType === 'AuthV3' || window.location.hash.toLowerCase().includes('auth0')) {
        return LimeadeAuthProviders.AUTH0;
      } else {
        throw new Error(`${ExceptionTypes.AUTH_PROVIDER_NOT_SUPPORTED}: ${authConfig?.authType}`);
      }
    } catch (error: any) {
      AppAuthStore.resetAuthConfig();
      throw error;
    }
  };

  const loadTeamsUser = useData(
    async () => {
      try {
        if (!loading && !error) {
          // inTeams == false: that means we are in authentication flow (popup)
          if (inTeams) {
            const teamsUserAuthConfig: TeamsUserCredentialAuthConfig = {
              initiateLoginEndpoint: Config.startLoginPageUrl,
              clientId: Config.clientId,
            };
            const teamsFx = new TeamsUserCredential(teamsUserAuthConfig);
            const teamsUser = await teamsFx.getUserInfo();
            AppAuthStore.setTeamsUserState({ teamsUser, inTeams: true, isLoading: false });
          } else {
            const context: teamsjsApp.Context = await app.getContext();

            const userObjectId = context.user?.id;
            const userPrincipalName = context.user?.userPrincipalName;
            const tid = context.user?.tenant?.id;

            if (userObjectId && tid && userPrincipalName) {
              const teamsUser: UserInfo = {
                objectId: userObjectId,
                tenantId: tid,
                preferredUserName: userPrincipalName,
              };
              AppAuthStore.setTeamsUserState({ teamsUser, inTeams: false, isLoading: false });
            } else {
              throw new Error(ExceptionTypes.CANNOT_GET_MICROSOFT_TEAMS_CONTEXT);
            }
          }
        }
      } catch (err: any) {
        setErrorState(err);
      }
    },
    { auto: false }
  );

  const renderSpecificAuthProvider = (authProvider: LimeadeAuthProviders): JSX.Element => {
    const primaryIdpConnectionName = AppAuthStore.authConfig.primaryIdpConnectionName || undefined;

    try {
      if (authProvider === LimeadeAuthProviders.AUTH0) {
        const redirectUri = `${window.location.origin}/#/auth0logincallback`;

        return (
          <Auth0Provider
            useRefreshTokens={true}
            cacheLocation="localstorage"
            scope="openid profile email apiaccess offline_access"
            audience={AppAuthStore.authConfig.audience}
            clientId={AppAuthStore.authConfig.clientId}
            connection={primaryIdpConnectionName} // if The Auth Config API return the connection to IdP. we can bypass the "Select Connection" step
            domain={AppAuthStore.authConfig.authority}
            organization={AppAuthStore.authConfig.organizationId}
            onRedirectCallback={onRedirectCallback}
            redirectUri={redirectUri}
          >
            <Auth0ProviderWrapper>{children}</Auth0ProviderWrapper>
          </Auth0Provider>
        );
      } else {
        throw new Error(`${ExceptionTypes.AUTH_PROVIDER_NOT_SUPPORTED}: ${authProvider}`);
      }
    } catch (error: any) {
      setErrorState(error);
      throw error;
    }
  };

  const specificAuthProvider = useData(
    async () => {
      try {
        let authProvider = await getAuthProvider();
        AppAuthStore.setIsProviderLoaded(true);
        return renderSpecificAuthProvider(authProvider);
      } catch (error: any) {
        setErrorState(error);
      }
    },
    { auto: false }
  );

  useEffect(() => {
    const error = teamsFxData.error || AppAuthStore.teamsUserState?.error;
    if (error) {
      Logger.resolveStartupDataLoadedPromise();
      throw error;
    }
  }, [AppAuthStore.teamsUserState?.error, teamsFxData.error]);

  useEffect(() => {
    const shouldRenderSpecificAuthProvider = !!AppAuthStore.teamsUserState?.teamsUser;
    if (shouldRenderSpecificAuthProvider) {
      specificAuthProvider.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AppAuthStore.teamsUserState]);

  useEffect(() => {
    if (!teamsFxData.loading) {
      loadTeamsUser.reload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamsFxData.loading]);

  if (specificAuthProvider.loading || teamsFxData.loading) {
    return null;
  }

  return <>{specificAuthProvider?.data}</>;
});

export default AuthProvider;
