import React, { useEffect, useState } from 'react';
import { app as teamsjsApp } from '@microsoft/teams-js';

import { app } from 'src/auth/TeamsJsWrapper';
import { useData } from 'src/lib/useData';
import LimeadeLoader from '../Loader/Loader';
import { useStore } from 'src/stores/Root.store';
import { DiscoverActivityTypes, NotificationType, RoutePath, Tabs } from 'src/utilities/constants';
import logNotification from 'src/utilities/logNotification';
import { useHistory } from 'react-router-dom';
import { ActivityFeed } from 'src/models/ActivityFeed';
import { Invite } from 'src/models/Invite';
import InvitationPopUp from 'src/components/dialogs/InvitationPopUp';
import { isAuthorization } from 'src/utilities/utilities';
import { navigateToTeamsTab } from 'src/utilities/msTeamsUtilities';

export const NotificationReceiver: React.FC = () => {
  const { AppAuthStore, ActivityFilterStore } = useStore();
  const history = useHistory();
  const { data: context, loading: isContextLoading, error: contextError } = useData(app.getContext);
  const [invitation, setInvitation] = useState<ActivityFeed & Invite>();
  const invitationPopUp = AppAuthStore.isAuthenticated && invitation && <InvitationPopUp invitation={invitation} />;
  const subPageId = (context as teamsjsApp.Context)?.page?.subPageId;
  const pageId = (context as teamsjsApp.Context)?.page.id as Tabs;

  useEffect(() => {
    if (contextError) {
      throw contextError;
    }
  }, [contextError]);

  useEffect(() => {
    if (subPageId) {
      const subEntity = JSON.parse(subPageId);
      const notificationTimestamp = 'notificationTimestamp';

      if (!subEntity.timestamp) {
        const timestamp = Date.now();
        const newContext = { ...subEntity, timestamp };

        localStorage.setItem(notificationTimestamp, `${timestamp}`);
        navigateToTeamsTab(pageId, newContext);
      } else if (`${subEntity.timestamp}` === localStorage.getItem(notificationTimestamp)) {
        localStorage.removeItem(notificationTimestamp);

        switch (subEntity.notificationType) {
          case NotificationType.COMPLETE_ACTIVITY:
          case NotificationType.JOIN_TEAM:
          case NotificationType.CHEERS:
          case NotificationType.SECOND_WELCOME:
          case NotificationType.JOINED_ACTIVITY_REMINDER_LINK_CLICKED:
          case NotificationType.JOINED_ACTIVITY_REMINDER_BUTTON_CLICKED: {
            logNotification(subEntity);
            break;
          }
        }

        if (subEntity.notificationType === NotificationType.INVITATION) {
          setInvitation(subEntity);
        } else if (subEntity.notificationType === NotificationType.JOINED_ACTIVITY_REMINDER_BUTTON_CLICKED) {
          ActivityFilterStore.setSelectTypes([DiscoverActivityTypes.DEVICE_ENABLED]);
        } else if (subEntity.activityId) {
          history.push(RoutePath.ActivityDetailsView.replace(':id', subEntity.activityId));
        }
      }
    }
  }, [subPageId, pageId, history, ActivityFilterStore]);

  if (isContextLoading) return <LimeadeLoader />;

  return <div>{invitationPopUp}</div>;
};

export const NotificationReceiverWrapper = () => {
  if (isAuthorization()) {
    return null;
  }
  return <NotificationReceiver />;
};
