import { Header, Flex, ShorthandCollection, ShorthandValue, Table, TableRowProps } from '@fluentui/react-northstar';
import { VisuallyHidden } from '@reach/visually-hidden';

import EmphasizedText from '../EmphasizedText';
import { useTeamsFx } from 'src/lib/useTeamsFx';
import { useStore } from 'src/stores/Root.store';

interface IBoardProps {
  titleId?: string;
  tableId?: string;
  title?: string;
  activityTitle?: string;
  header: ShorthandValue<TableRowProps>;
  rows: ShorthandCollection<TableRowProps>;
  warning?: string;
  warningNotAddedToTeams?: React.ReactElement;
  tableRole?: string;
  ariaLabel?: string;
}

const Board: React.FC<IBoardProps> = ({
  title = '',
  header,
  rows,
  warning = '',
  activityTitle = '',
  warningNotAddedToTeams,
  titleId,
  tableId,
  tableRole = 'table',
  ariaLabel = '',
}) => {
  const { theme } = useTeamsFx();
  const backgroundHoverColor = theme.siteVariables.colorScheme.brand.borderActive;
  const rowBorderColor = theme.siteVariables.colorScheme.brand.border;
  const { LocalizationStore } = useStore();

  const headerTitle = !!title && <Header id={titleId} as="h2" content={title} />;
  const warningContent = !!warning && (
    <Flex>
      <EmphasizedText>{warning}</EmphasizedText>
    </Flex>
  );

  const titleAnnoucement = !!activityTitle && (
    <VisuallyHidden id="vhAvailableTeam">
      {LocalizationStore.getString('Fow_AvailableTeams_Announcement', String(rows.length), activityTitle)}
    </VisuallyHidden>
  );

  return (
    <>
      {headerTitle}

      {warningContent}

      {warningNotAddedToTeams}

      {titleAnnoucement}

      <Table
        role={tableRole}
        aria-label={ariaLabel}
        id={tableId}
        header={header}
        rows={rows}
        variables={{ backgroundColor: 'inherit', rowBorderColor, backgroundHoverColor }}
      />
    </>
  );
};
export default Board;
